import Chart from 'chart.js/auto'
import needlePlugin from '@/charts/needle'

export default class Gauge {
    constructor(ctx, config) {
        this.ctx = ctx
        this.config = config || {}

        this.chart = new Chart(this.ctx, {
            type: 'doughnut',
            plugins: [needlePlugin],
            data: {
                datasets: [{
                    data: this.config.zones.map(item => item.data),
                    needleValue: this.config.value,
                    backgroundColor: this.config.zones.map(item => item.color)
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                circumference: this.config.circumference !== undefined ? this.config.circumference : 180,
                rotation: this.config.rotation !== undefined ? this.config.rotation : 270,
                cutout: this.config.cutout !== undefined ? this.config.cutout : 36,
                aspectRatio: 2,
                borderWidth: 0,
                events: [],
                layout: {
                    autoPadding: true,
                    padding: {
                        bottom: this.config.paddingBottom !== undefined ? this.config.paddingBottom : 44
                    }
                },
                plugins: {
                    needle: {
                        valueRange: this.config.valueRange,
                        labelOffset: this.config.labelOffset !== undefined ? this.config.labelOffset : 20,
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false,
                    }
                }
            }
        })
    }
}
import { Controller } from '@hotwired/stimulus'
import { Popover } from 'bootstrap'

export default class extends Controller {
    static targets = ['html']
    static values = {
        content: String,
        container: String,
        trigger: { type: String, default: 'focus hover'},
        isHtml: { type: Boolean, default: false },
    }

    connect() {
        let content,
            isHtml = this.isHtmlValue

        if (this.hasHtmlTarget) {
            content = this.htmlTarget.innerHTML
            isHtml = true
        }
        else {
            content = this.contentValue
        }

        this.popover = new Popover(this.element, {
            container: this.hasContainerValue ? this.containerValue : false,
            content: content,
            html: isHtml,
            trigger: this.triggerValue,
            placement: 'auto'
        })
    }

    get content() {
        return this.data.get('content')
    }
}
const popperArrow = ({ element = '[data-popper-arrow]', padding = 8} = {}) => {

    return {
        name: 'arrow',
        options: {
            element: element,
            padding: padding
        }
    }
}

const popperSameWidth = () => {
    return {
        name: "sameWidth",
        enabled: true,
        phase: "beforeWrite",
        requires: ["computeStyles"],
        fn: ({ state }) => {
            state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        },
        effect: ({ state }) => {
            state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
        }
    }
}

export {
    popperArrow,
    popperSameWidth
}
import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import { bindEvent, unbindEvent } from '@/libs/binder'

export default class ModalController extends Controller {

    connect() {
        bindEvent(this, this.element, 'click', 'click')
        this.createModal()
    }

    disconnect() {
        unbindEvent(this, this.element, 'click', 'click')
        this.destroyModal()
    }

    createModal() {
        this.modal = new Modal(this.dialogId, this.modalOptions)
        this.modalTarget = this.modal ? this.modal._element : null
        
        if (this.modalTarget) {   
            bindEvent(this, this.modalTarget, 'show.bs.modal', 'modalShow')
            bindEvent(this, this.modalTarget, 'hidden.bs.modal', 'modalHidden')
        }
    }

    destroyModal() {
        if (this.modal) {
            this.modal.dispose()

            if (this.modalTarget) {
                this.modalTarget.classList.remove('fade', 'show')
                this.modalTarget.style.display = null
                this.modalTarget.classList.add('fade')
                unbindEvent(this, this.modalTarget, 'show.bs.modal', 'modalShow')
                unbindEvent(this, this.modalTarget, 'hidden.bs.modal', 'modalHidden')
                this.modalTarget = null
            }
            this.modal = null
        }
    }

    click(e) {
        e.preventDefault()
        this.open(e)
    }

    open(e) {
        e.preventDefault()
        this.modal.show(this.element)
    }

    close(e) {
        e.preventDefault()
        this.modal.hide()
    }
    
    modalShow(e) {}

    modalHidden(e) {}

    get dialogId() {
        return this.data.get('dialog')
    }

    get modalOptions() {
        return {
            backdrop: 'static',
        }
    }
}
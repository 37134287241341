window.i18nCatalog = window.i18nCatalog ? window.i18nCatalog : null

function pluralixFactory(pluralForm) {
    const functionBody = pluralForm ? `var v = (${pluralForm}); return typeof(v) == 'boolean' ? (v ? 1 : 0) : v;` : 'return (n == 1) ? 0 : 1'
    return new Function('n', functionBody)
}

function catalogFromJSON(jsonString) {
    try {
        const jsonCatalog = JSON.parse(jsonString)

        if (typeof jsonCatalog === 'object' && jsonCatalog !== null) {
            window.i18nCatalog = jsonCatalog
            window.i18nCatalog.pluralidx = pluralixFactory(jsonCatalog.plural)
        }
    } catch (e) {
        console.error('Failed to parse the provided JSON string.', e)
    }
}

async function catalogFromUrl(url) {
    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const dataString = await response.text();
        catalogFromJSON(dataString);
    } catch (error) {
        console.error('Error loading the catalog:', error);
    }
}



function gettext(msgid) {
    if (i18nCatalog?.catalog) {
        const translation = i18nCatalog.catalog[msgid]
        if (translation !== undefined) {
            return Array.isArray(translation) ? translation[0] : translation
        }
    }
    return msgid
}

function ngettext(singular, plural, count) {
    if (i18nCatalog?.catalog) {
        const value = i18nCatalog.catalog[singular]
        if (value !== undefined) {
            return Array.isArray(value) ? value[i18nCatalog.pluralidx(count)] : value
        }
    }
    return count == 1 ? singular : plural
}

function gettext_noop(msgid) {
    return msgid
}

function pgettext(context, msgid) {
    const value = gettext(context + '\x04' + msgid)

    if (value.indexOf('\x04') !== -1) {
        return msgid
    }

    return value
}

function npgettext(context, singular, plural, count) {
    const value = ngettext(context + '\x04' + singular, context + '\x04' + plural, count)

    if (value.indexOf('\x04') != -1) {
        return ngettext(singular, plural, count)
    }

    return value
}

function interpolate(fmtString, obj, named) {
    if (named) {
        return fmtString.replace(/%\(\w+\)s/g, match => String(obj[match.slice(2, -2)]))
    } else {
        return fmtString.replace(/%s/g, () => String(obj.shift()))
    }
}

function get_format(format_type) {
    const value = i18nCatalog?.formats?.[format_type]
    return value === undefined ? format_type : value
}


export {
    gettext,
    ngettext,
    gettext_noop,
    pgettext,
    npgettext,
    interpolate,
    get_format,
    catalogFromJSON,
    catalogFromUrl,
}

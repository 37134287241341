function truncateChars(str, length) {
    return str.length > length ? `${str.substring(0, length-1)}…` : str
}

function trimString(str, ch) {
    var start = 0, 
        end = str.length;

    while(start < end && str[start] === ch)
        ++start;

    while(end > start && str[end - 1] === ch)
        --end;

    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}


export {
    trimString,
    truncateChars,
}

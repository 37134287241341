import Quill from 'quill'

import { ClassAttributor, Scope, Registry } from 'parchment'
import { BlockEmbed } from 'quill/blots/block'
import { ImageTooltip } from './tooltip'
import icons from './icons'
import { bindEvent, unbindEvent } from '../binder'
import { ngettext, interpolate } from "@/libs/i18n"
import { trimString } from '@/utils/common'

import Block from 'quill/blots/block'
import Break from 'quill/blots/break'
import Container from 'quill/blots/container'
import Cursor from 'quill/blots/cursor'
import Inline from 'quill/blots/inline'
import Scroll from 'quill/blots/scroll'
import Text from 'quill/blots/text'

import Bold from 'quill/formats/bold'
import Italic from 'quill/formats/italic'
import Underline from 'quill/formats/underline'
import { ListContainer, default as ListItem } from 'quill/formats/list'
import Link from 'quill/formats/link'
import Image from 'quill/formats/image'


const richRegistry = new Registry()
const plainRegistry = new Registry()

class DividerBlot extends BlockEmbed {
    static blotName = 'divider'
    static tagName = 'hr'
  }

const SizeClass = new ClassAttributor('size', 'text-size', {
  scope: Scope.INLINE,
  whitelist: ['small', 'large', 'huge'],
})

const AlignClass = new ClassAttributor('align', 'text-align', {
    scope: Scope.BLOCK,
    whitelist: ['right', 'center', 'justify'],
})

plainRegistry.register(
    Block, Break, Container, Cursor, Inline, Scroll, Text,
)

richRegistry.register(
    Block, Break, Container, Cursor, Inline, Scroll, Text,
    Bold, Italic, Underline,
    ListContainer, ListItem,
    Link, Image,
    SizeClass, AlignClass, DividerBlot,
)

const defaultOptions = {
    outputFormat: 'html',
    maxlength: null,
    placeholder: null,
    lang: null,
}

export default class RichTextEditor {
    constructor(element, options) {
        this.element = element
        this.options = Object.assign({}, defaultOptions, options)
        this.isFocused = false
        const editor = this.element.querySelector('[data-richtexteditor-target="editor"]')
        
        this.input = this.element.querySelector('[data-richtexteditor-target="input"]')
        this.toolbar = this.element.querySelector('[data-richtexteditor-target="toolbar"]')
        this.toolbarItems = {
            link: this.element.querySelector('[data-richtexteditor-target="linkBtn"]'),
            spell: this.element.querySelector('[data-richtexteditor-target="spellBtn"]'),
            undo: this.element.querySelector('[data-richtexteditor-target="undoBtn"]'),
            redo: this.element.querySelector('[data-richtexteditor-target="redoBtn"]'),
        }
        
        this.outputFormat = this.options.outputFormat
        this.maxlength = this.options.maxlength
        this.charsleft = this.toolbar && this.maxlength ? this.toolbar.querySelector('[data-richtexteditor-target="charsleft"]') : null

        this.editor = new Quill(editor, {
            registry: this.outputFormat === 'text' ? plainRegistry : richRegistry,
            modules: {
                toolbar: {
                    container: this.toolbar,
                    handlers: {
                        image: () => this.imageHandler(),
                        divider: () => this.dividerHandler(),
                        variable: (value) => this.variableHandler(value),
                        undo: () => this.undoHandler(),
                        redo: () => this.redoHandler(),
                    }
                },
                keyboard: {
                    bindings: {
                        'tab': {
                            key: 9,
                            handler: function(range, context) {
                                return true;
                            }
                        }
                    }
                }
            },
            placeholder: this.options.placeholder,
            theme: 'snow'
        })

        if (this.options.lang) {
            this.editor.root.setAttribute("lang", this.options.lang)
        }

        if (this.toolbarItems.spell) {
            this.editor.root.spellcheck = false
        }

        this.editor.on('text-change', () => {
            this.updateUndoRedoButtons()
            this.updateCharsLeft()
            const contentLength = this.contentLength
            
            if (this.maxlength && contentLength > this.maxlength) {
                this.editor.deleteText(this.maxlength, this.editor.getLength())
            }

            if (contentLength == 0) {
                this.input.value = ''
            }
            else {

                this.input.value = this.outputFormat === 'text' ? this.editor.getText().trim() : this.editor.root.innerHTML.trim()
            }
            this.editor.focus()
        })

        this.editor.on('selection-change', (range, oldRange, source) => {
            if (this.toolbarItems.link) {
                this.toolbarItems.link.disabled = range == null || range.length === 0
            }
        })


        this.history = this.editor.getModule('history')
        this.imageTooltip = new ImageTooltip(this.editor, this.editor.options.bounds)
        this.initializeToolbar()

        bindEvent(this, this.element, 'focusin', 'handleFocusin')
        bindEvent(this, document, 'richtext:focus', 'focused')
        return this
    }

    destroy() {
        unbindEvent(this, this.element, 'focusin', 'handleFocusin')
        unbindEvent(this, document, 'richtext:focus', 'focused')
        this.editor.off('text-change')
        this.editor.off('selection-change')
        this.editor = null
        this.imageTooltip = null
    }

    handleFocusin(e) {
        const event = new CustomEvent('richtext:focus', { detail: { instance: this.element } })
        document.dispatchEvent(event)
    }

    focused(e) {
        this.element.classList.toggle('richtext-focused', e.detail.instance === this.element)
    }

    initializeToolbar() {
        this.setPickerIcon('variable', icons['variable'])
        this.setPickerIcon('size', icons['dropdownSelector'])
        this.updateUndoRedoButtons()
        
        if (this.toolbarItems.link) {
            this.toolbarItems.link.disabled = true
        }

        if (this.toolbarItems.spell) {
            bindEvent(this, this.toolbarItems.spell, 'click', 'spellcheckHandler')
            this.setToolbarIcon('.spellcheck', icons['spellcheck'])
            this.updateSpellcheckButton()

        }
        this.updateCharsLeft()
    }

    setToolbarIcon(selector, svgIcon) {
        if (this.toolbar) {
            const elem = this.toolbar.querySelector(selector)
            if (elem) {
                elem.innerHTML = svgIcon
            }
        }
    }

    setPickerIcon(name, svgIcon) {
        this.setToolbarIcon(`span.ql-${name} .ql-picker-label`, svgIcon)
    }

    updateSpellcheckButton() {
        this.toolbarItems.spell.classList.toggle('ql-active', this.editor.root.spellcheck)
    }

    updateUndoRedoButtons() {
        if (this.toolbarItems.undo) {
            this.toolbarItems.undo.disabled = this.history.stack.undo.length === 0
        }
        if (this.toolbarItems.redo) {
            this.toolbarItems.redo.disabled = this.history.stack.redo.length === 0
        }
    }

    updateCharsLeft() {
        if (this.charsleft) {
            const remaining = this.maxlength - this.contentLength
            const fmts = ngettext(
                '<strong>%s</strong> char left.',
                '<strong>%s</strong> chars left.',
                remaining
            )

            this.charsleft.innerHTML = interpolate(fmts, [remaining])
        }
    }

    imageHandler() {
        if (this.imageTooltip) {
            this.imageTooltip.edit()
        }
    }
    
    dividerHandler() {
        const currentPosition = this.editor.getSelection().index
        this.editor.insertEmbed(currentPosition, 'divider', true)
        this.editor.setSelection(currentPosition + 1)
    }
    
    variableHandler(value) {
        const currentPosition = this.editor.getSelection().index
        this.editor.insertText(currentPosition, value)
        this.editor.setSelection(currentPosition + value.length)
    }

    spellcheckHandler(e) {
        e.preventDefault()
        this.editor.root.spellcheck = !this.editor.root.spellcheck
        this.editor.root.focus()
        this.updateSpellcheckButton()
        return false
    }

    undoHandler() {
        this.editor.history.undo()
    }

    redoHandler() {
        this.editor.history.redo()
    }

    get contentLength() {
        const length = trimString(this.editor.getText(), '\n').length
        return length == 1 && this.editor.getText() == '\n' ? 0 : length
    }
}

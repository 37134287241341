import { Chart } from 'chart.js'

function calcPercentInRange(value, range) {
    const min = range[0]
    return (value - min) / (range[1] - min)
}

function calcValueFromPercent(percent, range) {
    const min = range[0]
    return min + (percent * (range[1] - min));;
  }

const needlePlugin = {
    'id': 'needle',
    afterDatasetDraw(chart, args, options) {
        const { ctx, data: { datasets: [dataset]}, config } = chart
        const meta = chart.getDatasetMeta(0).data[0]
        
        const stripWidth = meta.outerRadius - meta.innerRadius
        const needleWidth = 10
        const radius = needleWidth/2
        let needleValue

        if (options.valueRange) {
            needleValue = calcPercentInRange(dataset.needleValue, options.valueRange) * 100
        }
        else {
            needleValue = dataset.needleValue
        }
        
        const progress = (meta.circumference / Math.PI) / dataset.data[0] * needleValue
        const angle = (progress) * Math.PI + config.options.rotation * (Math.PI / 180);

        ctx.save()
        ctx.translate(meta.x, meta.y)
        ctx.strokeStyle = 'black'
        ctx.fillStyle = 'black'

        // Needle
        ctx.rotate(angle);
        ctx.beginPath()
        ctx.moveTo(0 - radius, 0)
        ctx.lineTo(0, 0 - meta.innerRadius - stripWidth/2)
        ctx.lineTo(0 + radius, 0)
        ctx.closePath()
        // ctx.stroke()
        ctx.fill()
        ctx.rotate(-angle);

        // Dot
        ctx.beginPath()
        ctx.arc(0, 0, radius, 0, Math.PI * 2)
        // ctx.stroke()
        ctx.fill()
        ctx.closePath()

        // Label
        ctx.beginPath()
        ctx.font = `500 24px ${Chart.defaults.font.family}`
        ctx.fillStyle = 'black'
        // ctx.textBaseline = 'top';
        ctx.textAlign = 'center';

        const labelValue = progress/(config.options.circumference / 180)
        let labelText

        if (options.valueRange) {
            labelText = calcValueFromPercent(labelValue, options.valueRange).toFixed(0)
        }
        else {
            labelText = `${(labelValue * 100).toFixed(0)} %`
        }

        ctx.fillText(labelText, 0, 24 + options.labelOffset)
        ctx.closePath()

        ctx.restore()
    }
}

export default needlePlugin
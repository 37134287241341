import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [ 'element' ]
    static values = {
        animated: { type: Boolean, default: true }
    }

    connect() {
        this.inTransition = false

        if (!this.isVisible) {
            this.element.classList.add('collapsed')
        }
    }

    toggle(e) {
        if (this.isVisible) {
            this.hide(e)
        }
        else {
            this.show(e)
        }
    }

    show(e) {
        if (!this.hasElementTarget && (this.inTransition || this.isVisible)) {
            return
        }

        this.elementTarget.classList.remove('hidden')
        this.elementTarget.classList.add('collapsing')
        this.elementTarget.style.height = 0;
        this.element.classList.remove('collapsed')

        this.inTransition = true;

        const completed = (e) => {
            if (e && e.type) {
                this.elementTarget.removeEventListener(e.type, completed)
            }
            this.inTransition = false;
            this.elementTarget.classList.remove('collapsing')
            this.elementTarget.style.height = ''
        }

        if (this.animatedValue) {
            this.elementTarget.addEventListener('transitionend', completed, false)
        }
        this.elementTarget.style.height = `${this.elementTarget.scrollHeight}px`

        if (!this.animatedValue) {
            completed()
        }
    }

    hide(e) {
        if (!this.hasElementTarget && (this.inTransition || !this.isVisible)) {
            return
        }

        this.elementTarget.style.height = `${this.elementTarget.getBoundingClientRect().height}px`
        this.element.offsetHeight
        this.elementTarget.classList.add('collapsing')
        this.elementTarget.classList.remove('hidden')
        this.element.classList.add('collapsed')

        this.inTransition = true

        const completed = (e) => {
            if (e && e.type) {
                this.elementTarget.removeEventListener(e.type, completed)
            }
            this.inTransition = false;
            this.elementTarget.classList.remove('collapsing')
            this.elementTarget.classList.add('hidden')
        }
        
        this.elementTarget.style.height = ''

        if (this.animatedValue) {
            this.elementTarget.addEventListener('transitionend', completed, false)
        }
        else {
            completed()
        }
    }

    get isVisible() {
        return this.hasElementTarget && !this.elementTarget.classList.contains('hidden')
    }
}
import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import { bindInput, unbindInput } from '@/libs/binder'

export default class AutoSubmitController extends Controller {
    static debounces = ['submit']

    connect() {
        this.element.autoSubmitController = true

        if (this.debounceValue) {
            useDebounce(this, {wait: this.debounceValue})
        }
        bindInput(this, this.element, 'input')
    }

    disconnect() {
        this.element.autoSubmitController = undefined
        unbindInput(this, this.element, 'input') 
    }

    inputValueChanged(value, oldValue) {
        this.submit()
    }

    submit() {
        if (this.element.form) {
            if (this.element.form.method.toLowerCase() == 'get') {
                const queryString = new URLSearchParams(new FormData(this.element.form)).toString()
                window.location.href = `${window.location.href.split('?')[0]}?${queryString}`
            }
            else {
                this.element.form.requestSubmit()
            }
        }
    }

    get debounceValue() {
        const value = this.data.get('debounced') || 'false'

        if (value == 'false')  {
            return false
        }
        else if (value == 'true') {
            return 300
        }

        return parseInt(value)
    }
}
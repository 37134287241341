import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
    static targets = ['formContent']
    static values = {
        csrf: String
    }


    async submit(e) {
        const data = new FormData(e.target.form)
        const location = e.target.form.action || window.location.href
        try {
            const resp = await fetch(location, {
                method: 'POST',
                body: data,
                headers: this.getHeaders()
            })
            
            if (resp.ok) {
                this.formContentTarget.innerHTML = await resp.text()
            }
        }
        catch(e) {
            console.log(e)
        }
    }

    getHeaders() {
        const headers = {
            'X-Faux-Submit': 'true'
        }
        if (this.hasCsrfValue) {
            headers['X-CSRFToken'] = this.csrfValue
        }
        return headers
    }
}
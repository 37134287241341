import FormsetController from '@/app/controllers/formset_controller'

export default class extends FormsetController {
    static targets = ['body', 'emptyForm', 'form', 'btnDelete', 'btnMoveUp', 'btnMoveDown', 'defaultBlock', 'labelElse', 'labelAlways']

    updateFormsetState(setOrder, reindex) {
        this.defaultBlockTarget.classList.toggle('is-else', this.activeFormCount > 0)
        super.updateFormsetState(setOrder, reindex)
    }

}
import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { english } from 'flatpickr/dist/l10n/default.js'
import { Slovenian } from 'flatpickr/dist/l10n/sl.js'
import { Croatian } from 'flatpickr/dist/l10n/hr.js'

export default class extends Controller {
    initialize() {
        const pickerContainer = document.getElementById('flatpickr-container')

        this.picker = flatpickr(this.element, {
            allowInput: true,
            noCalendar: !this.showDatePicker,
            enableTime: this.showTimePicker,
            dateFormat: this.dateFormat,
            locale: this.locale,
            time_24hr: true,
            autoFillDefaultTime: false,
            monthSelectorType: 'static',
            nextArrow: '<i class="fas fa-caret-right font-lg"></i>',
            prevArrow: '<i class="fas fa-caret-left font-lg" ></i>',
        })
    }

    get showDatePicker() {
        return JSON.parse(this.data.get('showDatePicker'))
    }

    get showTimePicker() {
        return JSON.parse(this.data.get('showTimePicker'))
    }

    get dateFormat() {
        return this.data.get('dateFormat')
    }

    get locale() {
        let langMapper = {
            'en': english,
            'sl': Slovenian,
            'hr': Croatian
        } 
        return langMapper[document.documentElement.lang]
    }
}
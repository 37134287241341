import { Controller } from '@hotwired/stimulus'
import { parseElement } from '@/utils/parse'

export default class extends Controller {
    static targets = ['tab', 'panel', 'activeField']
    static classes = ['hidden', 'activeTab']
    
    connect() {
        let fromHash = null;
        
        if (window.location.hash) {
            const key = window.location.hash.substring(1)

            if (this.tabTargets.find(el => el.getAttribute('data-tabs-panel') == key)) {
                fromHash = key
            }
        }
        
        const errorTab = this.displayErrorCounts()
        this.activeTab = errorTab || fromHash || this.data.get('activeTab') || (this.tabTargets[0] ? this.tabTargets[0].getAttribute('data-tabs-panel') : undefined)
    }
    
    change(e) {
        e.preventDefault()
        const key = (e.detail && e.detail.tab) ? e.detail.tab : e.currentTarget.getAttribute('data-tabs-panel')
        this.activeTab = key
    }
    
    showTab() {
        const activeTab = this.activeTab

        this.tabTargets.forEach((tab) => {    
            const key = tab.getAttribute('data-tabs-panel')
            const panel = this.panelTargets.find(el => el.getAttribute('data-tabs-panel') == key)
            
            tab.classList.toggle(this.activeTabClass, activeTab == key)
            
            if (panel) {
                panel.classList.toggle(this.hiddenClass, activeTab != key)
            }
            else {
                console.log(`Panel '${key}' not found.`)
            }
        })
    }

    displayErrorCounts() {
        let activeTab = null

        this.panelTargets.forEach((panel) => {
            const numErrors = panel.querySelectorAll('.is-invalid').length

            if (numErrors > 0) {
                const key = panel.getAttribute('data-tabs-panel')
                const tab = this.tabTargets.find(el => el.getAttribute('data-tabs-panel') == key)
                tab.appendChild(this.createErrorCount(numErrors))
                activeTab = activeTab || key
            }
        })

        return activeTab
    }

    createErrorCount(numErrors) {
        const el = parseElement(`<span class="nav-error-count"></span>`)
        el.innerHTML = numErrors
        return el
    }
    
    get activeTab() {
        return this.data.get('activeTab')
    }
    
    set activeTab(value) {
        this.data.set('activeTab', value)
        if (this.hasActiveFieldTarget) {
            this.activeFieldTarget.value = value
        }
        this.showTab()
    }
}
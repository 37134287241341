import { Modal } from 'bootstrap'
import * as Turbo from "@hotwired/turbo"
import { bindEvent, unbindEvent } from '@/libs/binder'
import ModalController from '@/app/controllers/modal_controller'

export default class extends ModalController {
    static values = {
        url: String,
        size: String,
        mode: { type: String, default: 'sidepanel' }
    }

    connect() {
        bindEvent(this, this.element, 'click', 'click')
        this.dialogClasses = []

        if (this.hasSizeValue) {
            this.dialogClasses.push(this.sizeValue)
        }

        if (this.modeValue == 'sidepanel') {
            this.dialogClasses.push('modal-sidepanel')
        }

    }   

    disconnect() {
        unbindEvent(this, this.element, 'click', 'click')
        this.dialogClasses = []
    }

    click(e) {
        e.preventDefault()
        
        if (this.hasUrlValue || this.element.hasAttribute('href')) {
            this.createModal()

            if (this.modalTarget) {
                bindEvent(this, this.modalTarget, 'ajaxmodal:load', 'load')
                bindEvent(this, this.modalTarget, 'ajaxmodal:close', 'close')
            }

            if (this.dialogClasses.length) {
                this.dialogTarget.classList.add(...this.dialogClasses)
            }

            this.open(this.urlValue || this.element.getAttribute('href'))
        }
    }

    open(url) {
        this.contentTarget.src = url
        this.contentTarget.loaded.then(() => {
            this.modal.show(this.element)
        })
    }

    load(e) {}

    close(e) {
        let location = document.location.href,
            action = 'replace'

        if (e.detail && e.detail.location) {
            location = e.detail.location
            action = 'advance'
        }

        const onTurboLoad = (e) => {
            Turbo.cache.clear()
            document.removeEventListener('turbo:load', onTurboLoad)
        }
        const modalTarget = this.modalTarget

        const onHidden = (e) => {
            Turbo.visit(location, {action: action})
            document.addEventListener('turo:load', onTurboLoad)
            modalTarget.removeEventListener('hidden.bs.modal', onHidden)
        }
        this.modalTarget.addEventListener('hidden.bs.modal', onHidden, false)
        
        this.modal.hide()
    }

    modalHidden(e) {
        super.modalHidden(e)

        if (this.dialogClasses.length) {
            this.dialogTarget.classList.remove(...this.dialogClasses)
        }

        if (this.modal) {
            if (this.modalTarget) {
                unbindEvent(this, this.modalTarget, 'ajaxmodal:load', 'load')
                unbindEvent(this, this.modalTarget, 'ajaxmodal:close', 'close')
            }
            this.destroyModal()
        }
    }

    get dialogId() {
        return '#ajaxmodal'
    }

    get dialogTarget() {
        return this.modalTarget.querySelector('[data-ajaxmodal-target="dialog"]')
    }

    get contentTarget() {
        return this.modalTarget.querySelector('[data-ajaxmodal-target="content"]')
    }

    get formTarget() {
        return this.modalTarget.querySelector('[data-ajaxmodal-target="form"]')
    }

}
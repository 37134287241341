import { Controller } from '@hotwired/stimulus'
import { Dropdown } from 'bootstrap'
import { bindEvent, unbindEvent } from '@/libs/binder'
import { popperArrow } from '@/utils/popper'


export default class DropdownController extends Controller {
    static targets = ['toggle']
    static values = {
        offset: { type: Array, default: [0,2] },
        showArrow: { type: Boolean, default: false },
        centered: { type: Boolean, default: false }
    }

    connect() {
        this.element.classList.add(this.dropdownClass)
        this.toggleTarget.setAttribute('data-bs-toggle', 'dropdown')
        this.dropdown = new Dropdown(this.toggleTarget, {
            offset: this.offsetValue,
            popperConfig: (defaultConfig) => {
                if (this.showArrowValue) {
                    defaultConfig.modifiers.push(popperArrow({ element: '[data-dropdown-arrow]' }))
                }
                defaultConfig.strategy = 'fixed'
                return defaultConfig
            }
        })

        bindEvent(this, this.element, 'shown.bs.dropdown', 'dropdownShown')
        bindEvent(this, this.element, 'hidden.bs.dropdown', 'dropdownHidden')
    }

    disconnect() {
        unbindEvent(this, this.element, 'shown.bs.dropdown', 'dropdownShown')
        unbindEvent(this, this.element, 'hidden.bs.dropdown', 'dropdownHidden')
        this.dropdown.dispose()
    }

    dropdownShown(e) {}

    dropdownHidden(e) {}

    get dropdownClass() {
        return this.centeredValue ? 'dropdown-center' : 'dropdown'
    }
}

import { BaseTooltip } from 'quill/themes/base'
import Emitter from 'quill/core/emitter'


class ImageTooltip extends BaseTooltip {
    static TEMPLATE = [
        '<input type="text" class="ql-image-url" placeholder="Enter image URL here" />',
        '<a class="ql-action"></a>'
    ].join('')

    

    constructor(quill, bounds) {
        super(quill, bounds)
        this.currentPosition = 0   
    }


    listen() {
        super.listen()

        this.root.querySelector('.ql-action').addEventListener('click', (event) => {
            const value = this.textbox.value
            
            if (value) {
                this.save()
            }

            event.preventDefault()
        })

        this.quill.on(Emitter.events.SELECTION_CHANGE, (range) => {
            if (range == null) return;
            this.currentPosition = range.index
            this.hide()
        })
    }

    edit() {
        this.root.classList.remove('ql-hidden')
        this.root.classList.add('ql-editing')
        const bounds = this.quill.getBounds(this.quill.selection.savedRange)
        
        if (bounds != null) {
            this.position(bounds)
        }

        this.textbox.select()
    }

    save() {
        const value = this.textbox.value
        this.quill.insertEmbed(this.currentPosition, 'image', value, Emitter.sources.USER)
        this.hide()
    }
}

export {
    ImageTooltip
}

import Sortable from 'sortablejs/modular/sortable.core.esm.js'
import FormsetController from '@/app/controllers/formset_controller'
import { parseElement } from '@/utils/parse'

export default class extends FormsetController {
    static targets = ['body', 'form', 'questions']
    static outlets = ['ajaxmodal']
    
    connect() {
        super.connect()

        this.sortable = Sortable.create(this.questionsTarget, {
            dataIdAttr: 'data-question-index',
            onUpdate: (e) => {
                const index = e.item.getAttribute('data-question-index')
                const direction = e.oldIndex - e.newIndex
                const form = this.bodyTarget.querySelector(`[data-form-index="${index}"]`)
                this.move(form, direction)
            },
        
        })
    }

    disconnect() {
        this.sortable.destroy()
    }

    getAddFormUrlParams(e) {
        return {
            answer_type: e.params.value,
            count: this.formCount,
        }
    }

    handleAddFormResponse(resp) {
        const form = parseElement(resp.data.form.trim().replace(new RegExp('__prefix__', 'g'), resp.data.index))
        const question = parseElement(resp.data.question.trim())
        
        this.questionsTarget.appendChild(question)
        this.addForm(this.formCount, form)
        this.dispatch('question-added', {
            detail: {
                tab: `question-${resp.data.index}-tab`
            }
        })
    }

    delete(e) {
        if (!this.canDelete) {
            return false
        }

        const form = this.bodyTarget.querySelector(`[data-form-index="${e.detail.index}"]`)
        const question = this.questionsTarget.querySelector(`li[data-question-index="${e.detail.index}"]`)
        const prevIndex = question.previousElementSibling ? question.previousElementSibling.getAttribute('data-question-index') : null
        
        this.deleteForm(form)
        this.questionsTarget.removeChild(question)
        
        if (prevIndex !== null) {
            this.dispatch('question-added', {
                detail: {
                    tab: `question-${prevIndex}-tab`
                }
            })
        }

        this.ajaxmodalOutlet.modal.hide()
    }

    updateFormIndex(form, index) {
        const formIndex = this.getFormIndex(form)
        
        if (index != formIndex) {
            const tabPanel = `question-${index}-tab`
            const question = this.questionsTarget.querySelector(`li[data-question-index="${formIndex}"]`)
            const questionLink = question.querySelector(`a[data-tabs-panel="question-${formIndex}-tab"]`)

            question.setAttribute('data-question-index', index)
            questionLink.setAttribute('data-tabs-panel', tabPanel)
            form.setAttribute('data-tabs-panel', tabPanel)
        }

        super.updateFormIndex(form, index)
    }

    getDeleteButton(form, index) {
        return this.questionsTarget.querySelector(`li[data-question-index="${index}"] [data-form-button="delete"]`)
    }

    get isAnimated() {
        return false
    }
}
const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
        const text = []

        items.forEach((label, i) => {
            text.push(`<li><span class="${label.pointStyle}" style="color:${label.fillStyle}; background-color:${label.fillStyle}"></span>${label.text}</li>`)    
        })
        
        options.container.innerHTML = `<ul class="c${chart.id}-legend">${text.join('')}</ul>`
    }
}

export default htmlLegendPlugin
import { Controller } from '@hotwired/stimulus'
import Gauge from '@/charts/gauge'
import { Color, CHART_COLORS } from '@/utils/color'


export default class CsatController extends Controller {
    static targets = ['gaugeCtx', 'gaugeLabel']
    static values = {
        data: Number
    }

    initialize() {        
        this.gauge = new Gauge(this.gaugeCtxTarget, {
            value: this.dataValue,
            zones: [
                {data: 50, color: this.detractorsColor},
                {data: 30, color: this.passivesColor},
                {data: 20, color: this.promotersColor},
            ]
        })
    }

    get promotersColor() {
        return new Color(CHART_COLORS.green).lighten(0.1).rgbString()
    }

    get passivesColor() {
        return new Color(CHART_COLORS.yellow).lighten(0.1).rgbString()
    }

    get detractorsColor() {
        return new Color(CHART_COLORS.red).lighten(0.1).rgbString()
    }
}
import { Controller } from '@hotwired/stimulus'
import { bindEvent, unbindEvent } from '@/libs/binder'

export default class extends Controller {
    static targets = ['sticky']
    static values = { offset: Number }

    connect() {
        this.offset = this.hasOffsetValue ? this.offsetValue : 72
        this.isSticky = false;

        bindEvent(this, document, 'scroll', 'scrolled')
        bindEvent(this, window, 'resize', 'resized')
    }

    disconnect() {
        unbindEvent(this, document, 'scroll', 'scrolled')
        unbindEvent(this, window, 'resize', 'resized')
    }
    
    scrolled(e) {
        if(this.stickyTarget.offsetHeight < window.innerHeight && document.scrollingElement.scrollTop >= this.offset) {
            this.stick(e)
        }
        else {
            this.unstick(e)
        } 

    }

    resized(e) {
        if (this.isSticky) {
            this.stickyTarget.style.width = this.element.offsetWidth + 'px'
        }
    }

    stick(e) {
        if (!this.isSticky) {
            this.isSticky = true
            this.stickyTarget.classList.add('md:fixed')
            this.stickyTarget.style.top = (this.offset - 1) + 'px'
            this.stickyTarget.style.width = this.element.offsetWidth + 'px'
            
        }
    }

    unstick(e) {
        if (this.isSticky) {
            this.isSticky = false
            this.stickyTarget.classList.remove('md:fixed')
            this.stickyTarget.style.top = null
            this.stickyTarget.style.width = null
            
        }
    }
}
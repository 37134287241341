import { Controller } from '@hotwired/stimulus'
import * as hoverintet from 'hoverintent'
import { bindEvent, unbindEvent } from '@/libs/binder'


export default class extends Controller {
    static targets = ['sidebar', 'errorModal', 'errorModalBody']
    static values = {
        sidebar: String
    }

    connect() {
        if (this.sidebarExpendable) {
            this.hoverintet = hoverintet(this.sidebarTarget, this.onSidebarOver.bind(this), this.onSidebarOut.bind(this))
            this.hoverintet.options({
                interval: 300,
                timeout: 300,
            })
        }

        bindEvent(this, document, 'turbo:before-cache', 'onBeforeCache')
    }

    disconnect() {
        unbindEvent(this, document, 'turbo:before-cache', 'onBeforeCache')
        if (this.hoverintet) {
            this.hoverintet.remove()
        }
    }

    onSidebarOver(e) {
        this.sidebarTarget.classList.add('sidebar-expanded')
    }

    onSidebarOut(e) {
        this.sidebarTarget.classList.remove('sidebar-expanded')
    }

    onBeforeCache(e) {
        if (this.sidebarExpendable) {
            this.sidebarTarget.classList.remove('sidebar.expanded')
        }
    }

    get sidebarExpendable() {
        return this.hasSidebarTarget && this.sidebarValue == 'auto'
    }

    // handleException(e) {
    //     const openModal = $('.modal.show')
        
    //     this.errorModalBodyTarget.innerHTML = '<p>' + gettext('An unexpected error occurred. Please try again') + '</p>'
    //     $(this.errorModalTarget).modal()
        
    //     if (openModal) {
    //         openModal.modal('hide')

    //         $(this.errorModalTarget).one('hide.bs.modal', e => {
    //             openModal.modal('show')
    //         })
    //     }
    // }
}
import 'quill/dist/quill.snow.css'
import 'css/app/style.css'

import 'form-request-submit-polyfill'
 
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import * as Turbo from "@hotwired/turbo"

import { eventDispatcher } from '@/libs/json-event'


document.addEventListener('click', (e) => {
    if(e.target.hasAttribute('data-inner-click')) {
        e.stopImmediatePropagation()
        e.target.dispatchEvent(new Event('inner:click'))
    }
}, true)

document.addEventListener('turbo:before-fetch-request', (e) => {
    if (e.detail.fetchOptions.headers['Turbo-Frame']) {
        e.detail.fetchOptions.headers.Accept += ', application/vnd.json-event.json'
    }
})

document.addEventListener('turbo:before-fetch-response', (e) => {
    const contentType = e.detail.fetchResponse.response.headers.get('content-type')

    if (contentType == 'application/vnd.json-event.json') {
        e.preventDefault()
        e.detail.fetchResponse.response.json().then((data) => {
            eventDispatcher.create(data).dispatch()
        })
    }
})

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))


export function bindInput(obj, el, prop) {
    const propName = `${prop}Value`
    const propChanged = `${propName}Changed`
    const eventName = el.tagName == 'SELECT' ? 'change' : 'input'

    let propState
    
    Object.defineProperty(obj, propName, {
        configurable: true,
        get: () => {
            return el.value
        },
        set: (value) => {
            const oldValue = propState
            
            el.value = value
            propState = value

            if (obj[propChanged]) {
                obj[propChanged](value, oldValue)
            }
        }
    })

    el._binderOnInputHandler = function(e) {
        this[propName] = e.target.value
    }.bind(obj)
    
    el.addEventListener(eventName, el._binderOnInputHandler, false) 
}

export function unbindInput(obj, el, prop) {
    delete obj[`${prop}Value`]
    
    if (el._binderOnInputHandler) {
        const eventName = el.tagName == 'SELECT' ? 'change' : 'input'
        el.removeEventListener(eventName, el._binderOnInputHandler)
        delete el._binderOnInputHandler
    }
}

export function bindEvent(obj, el, eventName, eventHandler, options=false) {
    obj[eventHandler] = obj[eventHandler].bind(obj)
    if (Array.isArray(el)) {
        el.forEach((item) => {
            item.addEventListener(eventName, obj[eventHandler], options)
        })
    }
    else {
        el.addEventListener(eventName, obj[eventHandler], options)
    }
}

export function unbindEvent(obj, el, eventName, eventHandler, options=false) {
    if (Array.isArray(el)) {
        el.forEach((item) => {
            item.removeEventListener(eventName, obj[eventHandler], options)
        })
    }
    else {
        el.removeEventListener(eventName, obj[eventHandler], options)
    }
    delete obj[eventHandler]
}
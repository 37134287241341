class jsonEvent {
    constructor(config) {
        this.eventName = config.event,
        this.targetSelector = config.selector
        this.data = config.data
        this.event = new CustomEvent(this.eventName, this.data !== undefined ? {detail: this.data} : undefined)
    }

    dispatch() {
        if (this.targetSelector) {
            const targets = document.querySelectorAll(this.targetSelector)
            Array.from(targets).forEach(el => {
                el.dispatchEvent(this.event)
            })
        } else {
            document.dispatchEvent(this.event)
        }
    }
}

class jsonEventList {
    constructor(configList) {
        this.events = []

        if (Array.isArray(configList)) {
            configList.forEach(event => {
                this.events.push(event)
            })
        }
    }

    dispatch() {
        this.events.forEach(event => {
            event.dispatch()
        })
    }
}

class jsonEventDispatcher {
    parse(jsonString) {
        return this.create(JSON.parse(jsonString))
    }

    create(config) {
        return Array.isArray(config) ? new jsonEventList(config) : new jsonEvent(config)
    }
}

const eventDispatcher = new jsonEventDispatcher()

export {
    eventDispatcher,
    jsonEvent,
    jsonEventList
}

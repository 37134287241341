import { Controller } from '@hotwired/stimulus'
import { bindEvent, unbindEvent } from '@/libs/binder'
import { bind } from 'lodash'

export default class extends Controller {
    connect() {
        bindEvent(this, window, 'resize', 'resized')

        if (this.isEnoughHeight) {
            this.element.classList.add('flex-height')
        }
    }

    disconnect() {
        unbindEvent(this, window, 'resize', 'setWidth')
    }

    resized() {
        if (this.isEnoughHeight) {
            this.element.classList.add('flex-height')
        } else {
            this.element.classList.remove('flex-height')
        }
    }

    get isEnoughHeight() {
        return (window.innerHeight - this.element.offsetTop) / window.innerHeight > 0.6
    }
}
import { Controller } from '@hotwired/stimulus'
import { Color } from '@/utils/color'

export default class extends Controller {
    static targets = ['input']
    
    connect() {
        this.setColor()
    }

    disconnect() {
        this.element.style.removeProperty('--ifb-colorpicker-color')
    }

    setColor() {
        const color = this.color

        if (color) {
            this.element.style.setProperty('--ifb-colorpicker-color', color)
        }
        else {
            this.element.style.removeProperty('--ifb-colorpicker-color')
        }
    }

    validateColor(color) {
        const isValid = color ? new Color(color.trim()).valid : false
        this.element.classList.toggle('invalid-color', !isValid)
        return isValid
    }

    get color() {
        let colorValue = null
        
        if (this.inputTarget.value) {
            colorValue = this.inputTarget.value
        }
        else if (this.inputTarget.placeholder) {
            colorValue = this.inputTarget.placeholder
        }

        return this.validateColor(colorValue) ? colorValue : null
    }
}
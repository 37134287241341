import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import htmlLegendPlugin from '@/charts/html-legend';
import Gauge from '@/charts/gauge'

import { Color, CHART_COLORS } from '@/utils/color'


export default class NpsController extends Controller {
    static targets = ['chartCtx', 'chartLegend', 'gaugeCtx']
    static values = {
        data: Object,
        barHeight: Number,
        promotersLabel: String,
        passivesLabel: String,
        detractorsLabel: String,
    }

    initialize() {
        let npsData = this.dataValue

        if (this.hasChartCtxTarget) {
            this.initializeChart(npsData)
        }
        this.initializeGauge(npsData)
    }

    initializeChart(npsData) {
        this.chart = new Chart(this.chartCtxTarget, {
            type: 'bar',
            plugins: [ChartDataLabels, htmlLegendPlugin],
            data: {
                labels: ['Percent'],
                datasets: [
                    {
                        label: this.detractorsLabelValue,
                        backgroundColor: this.detractorsColor,
                        borderWidth: 0,
                        data: [npsData.detractors],
                        stack: 'nps'
                    },
                    {
                        label: this.passivesLabelValue,
                        backgroundColor: this.passivesColor,
                        borderWidth: 0,
                        data: [npsData.passives],
                        stack: 'nps'
                    },
                    {
                        label: this.promotersLabelValue,
                        backgroundColor: this.promotersColor,
                        borderWidth: 0,
                        data: [npsData.promoters],
                        stack: 'nps'
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                barThickness: this.hasBarHeightValue ? this.barHeightValue : 64,
                layout: {
                    autoPadding: false,
                    padding: 0
                },
                scales: {
                    x: {
                        stacked: true,
                        type: 'linear',
                        grid: {
                            display: true,
                            drawTicks: false,
                        },
                        border: {
                            display: false,
                        },
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 100,
                            padding: 10,
                        }
                    },
                    y: {
                        grid: { display: false },
                        border: { display: false },
                        ticks: { display: false},
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            pointStyle: 'rect',
                            usePointStyle: true,
                        },
                        events: []
                    },
                    htmlLegend: {
                        container: this.chartLegendTarget,
                    },
                    tooltip: {
                        mode: 'x',
                        callbacks: {
                            title: (tooltipItem) => {
                                return ''
                            },
                            label: (tooltipItem) => {
                                return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} %`
                            }
                        }
                    },
                    datalabels: {
                        font: {
                            weight: 'bold',
                            size: 11
                        },
                        padding: 2,
                        borderRadius: 4,
                        offset: 2,
                        display: (context) => {
                            context.dataValue = context.dataset.data[context.dataIndex]
                            context.isDisplayed = context.dataValue > 0
                            return context.isDisplayed

                        },
                        formatter: (value, context) => {
                            return value + ' %'
                        },
                        anchor: (context) => {
                            switch(context.datasetIndex) {
                                case 0: return 'start';
                                case 1: return 'center';
                                case 2: return 'end';
                            }
                        },
                        align: (context) => {
                            if (context.isDisplayed && context.datasetIndex == 0) {
                                return context.dataValue <= 12 ? 'start' : 'end'
                            }
                            else if (context.isDisplayed && context.datasetIndex == 2) {
                                return context.dataValue <= 12 ? 'end' : 'start'
                            }
                            return 'center'
                        },
                        color: context => {
                            const options = context.chart.getDatasetMeta(context.datasetIndex).data[0].options
                            if (new Color(options.backgroundColor).isDark()) {
                                return '#ffffff'   
                            }
                        }
                    }
                }
            }
        })
    }

    initializeGauge(npsData) {
        this.gauge = new Gauge(this.gaugeCtxTarget, {
            value: npsData.index,
            valueRange: [-100, 100],
            circumference: 270,
            rotation: 225,
            cutout: '65%',
            labelOffset: 40,
            paddingBottom: 20,
            zones: [
                {data: 35, color: this.detractorsColor},
                {data: 30, color: this.passivesColor},
                {data: 35, color: this.promotersColor},
            ]
        })          
    }

    get promotersColor() {
        return new Color(CHART_COLORS.green).lighten(0.1).rgbString()
    }

    get passivesColor() {
        return new Color(CHART_COLORS.yellow).lighten(0.1).rgbString()
    }

    get detractorsColor() {
        return new Color(CHART_COLORS.red).lighten(0.1).rgbString()
    }
}
import { Controller } from '@hotwired/stimulus'
import RichTextEditor from '@/libs/richtext/editor'


export default class extends Controller {
    static targets = ['input',]
    static values = {
        format: {type: String, default: 'html'},
        maxlength: {type: Number, default: null},
    }

    connect() {
        this.richtext = new RichTextEditor(this.element, {
            outputFormat: this.formatValue,
            maxlength: this.maxlengthValue,
            placeholder: this.inputTarget.placeholder ? this.inputTarget.placeholder : null,
            lang: this.inputTarget.lang ? this.inputTarget.lang : this.inputTarget.form.lang ? this.inputTarget.form.lang : null,
        })
    }

    disconnect() {
        this.richtext.destroy()
    }
}
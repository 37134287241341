function parseHTML(html) {
    const template = document.createElement('template');
    template.innerHTML = html;
    return template.content;
}

function parseElement(html) {
    return parseHTML(html).firstElementChild
}

export {
    parseHTML,
    parseElement
}
import { Controller } from '@hotwired/stimulus'
import { bindEvent, unbindEvent } from '@/libs/binder'

export default class extends Controller {
    static targets = ['matchSelect', 'conditionFrame']
    static values = {
        frameSrc: String,
        prefix: String
    }

    connect() {
        bindEvent(this, this.matchSelectTarget, 'change', 'matchValueChanged')
    }

    disconnect() {
        unbindEvent(this, this.matchSelectTarget, 'change', 'matchValueChanged')
    }

    matchValueChanged(e) {
        this.conditionFrameTarget.src = `${this.frameSrcValue}?question=${e.target.value}&prefix=${this.prefixValue}`
    }

}
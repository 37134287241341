import { Color } from '@kurkle/color'

const CHART_COLORS = {
    'red': '#ff3333',
    'green': '#20b97e',
    'blue': '#2491cf',
    'teal': '#20b9a4',
    'yellow': '#f8cb00',
}

Color.prototype.yiq = function() {
    return (this.rgb.r * 299 + this.rgb.g * 587 + this.rgb.b * 114) / 1000;
}

Color.prototype.isDark = function(threshold) { 
    return this.yiq() < (threshold || 160)
}

Color.prototype.isLight = function(threshold) {
    return !this.isDark(threshold)
}

export { 
    Color,
    CHART_COLORS
}
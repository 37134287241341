import { Controller } from '@hotwired/stimulus'
import { ngettext, interpolate } from "@/libs/i18n"

export default class extends Controller {
    connect() {
        this.element.classList.add('charsleft')
        this.counter = this.getCounter()
        this.update()

        this.eventHandler = function(e) {
            this.update()
        }.bind(this)

        this.element.addEventListener('keyup', this.eventHandler, false)
        this.element.addEventListener('change', this.eventHandler, false)
    }

    update() {
        let counts = this.calc(),
            fmts = ngettext(
                '<strong>%s</strong> char left.',
                '<strong>%s</strong> chars left.',
                counts.remaining
            )
        this.counter.innerHTML = interpolate(fmts, [counts.remaining])
    }

    calc() {
        let maxLength = this.element.getAttribute('maxlength'),
            textLength = this.element.value.length

        return {
            maxLength: maxLength,
            textLength: textLength,
            remaining: maxLength - textLength
        }
    }

    disconnect() {
        this.element.removeEventListener('keyup', this.eventHandler)
        this.element.removeEventListener('change', this.eventHandler)
    }

    getCounter() {
        let counter = this.element.parentElement.querySelector('.charsleft-counter')

        if (!counter) {
            counter = document.createElement('small')
            counter.classList.add('charsleft-counter', 'form-text', 'text-muted')
            this.element.insertAdjacentElement('afterend', counter)
        }

        return counter
    }
}
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['dismiss']

    connect() {
        this.element.classList.add('visible')
        
        if (this.autoDismiss) {
            setTimeout(() => {
                this.dismiss()
            }, this.autoDismiss * 1000)
        }
    }

    dismiss() {
        this.element.classList.remove('visible')
        
        let dismissedHandler = function(e) {
            if (e.propertyName == 'opacity') {
                this.element.removeEventListener(e.type, dismissedHandler)
                this.element.parentNode.removeChild(this.element)
            }
        }.bind(this) 

        this.element.addEventListener('transitionend', dismissedHandler, false)
    }

    get autoDismiss() {
        return JSON.parse(this.data.get('autoDismiss') || false) 
    }
}
import { Controller } from '@hotwired/stimulus'
import { bindInput, unbindInput } from '@/libs/binder'
import { truncateChars } from '@/utils/common'

export default class AnswerController extends Controller {
    static values = {
        answerType: String
    }

    connect() {
        this.questionInput = this.element.querySelector('input[id^=id_questions-][id$=-question]')
        bindInput(this, this.questionInput, 'question')
    }

    disconnect() {
        unbindInput(this, this.questionInput, 'question') 
    }

    questionValueChanged(value, oldValue) {
        const index = parseInt(this.element.getAttribute('data-form-index'))
        const questionText = document.querySelector(`.nav-questions li a[data-tabs-panel="question-${index}-tab"] .questions-item-text`)
        questionText.innerHTML = value.length ? truncateChars(value, 90) : '&nbsp;'
    }
}